import React from "react"
import Layout from "../components/layout"
import MenuIndex from "../components/menu-index"
import Styled from "styled-components"
import Lightlogo from "../components/lightlogo"
import Body from "../components/body"
import Footer from "../components/footer"
import "bootstrap/dist/css/bootstrap.css"

const Title = Styled.h1`
margin: auto;
text-align: center;
font-size: 10vw;
color: #485696;
`
const Hightlight = Styled.span`
-webkit-text-fill-color: #485696;
-webkit-text-stroke: 2px #ddd;
`
const Logo = Styled.div`
@media(max-width: 900px){
  display: none;
}
width: 4vw;
position: relative;
top: 5vh;
left: 2vw;
`

const Regions = Styled.div`
position: relative;
left: 2vw;
writing-mode: vertical-rl;
color: #ddd;
`

const MainTable = Styled.table`
@media (min-width: 900px){
  height: 100vh;
}
@media (max-width: 900px){
  height: 80vh;
}
`

const IndexPage = () => (
  <Layout style={{ backgroundColor: `black` }}
    lang="en" title="HAG Ventures" link="https://www.hag.ventures" description="HAG Ventures is a boutique that combines skills like consulting services, education, startups' creation and angel investment.">
    <MenuIndex
      MenuItem1={
        <Body>
          <MainTable>
            <tbody>
              <tr>
                <td className="align-middle">
                  <Logo>
                    <Lightlogo />
                  </Logo>
                  <Title>We are a <br /> <Hightlight>venture</Hightlight> <br /> builder</Title>
                  <Regions>
                    <p>Europe</p>
                    <p>LATAM</p>
                    <p>USA</p>
                  </Regions>
                </td>
              </tr>
            </tbody>
          </MainTable>
          <Footer />
        </Body>
      }
      Titles={["about", "services", "studio", "academy"]}
      Links={["/about", "https://www.hag.services", "https://www.hag.studio", "https://www.hag.academy"]}
    ></MenuIndex>
  </Layout>
)

export default IndexPage
