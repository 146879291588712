import React from "react"
import { Link } from "gatsby"
import Darklogo from "../components/darklogo"
import { Nav, Navbar } from "react-bootstrap"
import Styled from "styled-components"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBars, faTimes } from '@fortawesome/free-solid-svg-icons'

const MainMenu = Styled.div`
@media (min-width: 900px){
  display: flex;
  flex-direction: row;
}
`

const MobileMenu = Styled.div`
position: sticky;
top: 0;
z-index: 16;
@media (min-width: 900px){
  display: none;
}
.navbar{
  background-color: #ddd;
}
.nav-link{
  margin: auto;
}
.navbar-toggler{
  border: none;
  #collapsed{
    display: block;
  }
  #expanded{
    display: none;
  }
  &.collapsed{
    #collapsed{
      display: none;
    }
    #expanded{
      display: block;
    }
  }
}
`

const MenuTitle = Styled.p`
writing-mode: vertical-rl;
margin-left: auto;
margin-right: auto;
margin-top: 1em;
font-size: 1.5em;
`

const MenuItem = Styled.div`
min-height: 100vh;
width: 4%;
@media(max-width: 900px){
  display: none;
}
a{
  color: white !important;
}
p{
  color: white !important;
}
`

const Sticky = Styled.div`
position: sticky;
top: 2em;
`

const Menu = ({ MenuItem1, MenuItem2, MenuItem3, MenuItem4, MenuItem5, Titles, Links }) => (
  <MainMenu>
    <MobileMenu>
      <Navbar expand="lg">
        <Navbar.Brand href="/">
          <Darklogo style={{ width: `10vw` }} />
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav">
          <FontAwesomeIcon id="expanded" style={{ color: `#494949` }} icon={faBars} />
          <FontAwesomeIcon id="collapsed" style={{ color: `#494949` }} icon={faTimes} />
        </Navbar.Toggle>
        <Navbar.Collapse>
          <Nav style={{ height: `50vh` }}>
            <Nav.Link href={Links[0]}>{Titles[0]}</Nav.Link>
            <Nav.Link href={Links[1]}>{Titles[1]}</Nav.Link>
            <Nav.Link href={Links[2]}>{Titles[2]}</Nav.Link>
            <Nav.Link href={Links[3]}>{Titles[3]}</Nav.Link>
          </Nav>
        </Navbar.Collapse>
      </Navbar>
    </MobileMenu>
    {MenuItem1}
    <MenuItem style={{ backgroundColor: "#ddd" }}><Sticky><Link to={Links[0]}><MenuTitle>{Titles[0]}</MenuTitle></Link></Sticky></MenuItem>
    {MenuItem2}
    <MenuItem style={{ backgroundColor: "#494949" }}><Sticky><Link to={Links[1]}><MenuTitle>{Titles[1]}</MenuTitle></Link></Sticky></MenuItem>
    {MenuItem3}
    <MenuItem style={{ backgroundColor: "black" }}><Sticky><Link to={Links[2]}><MenuTitle>{Titles[2]}</MenuTitle></Link></Sticky></MenuItem>
    {MenuItem4}
    <MenuItem style={{ backgroundColor: "#485696" }}><Sticky><Link to={Links[3]}><MenuTitle>{Titles[3]}</MenuTitle></Link></Sticky></MenuItem>
    {MenuItem5}
  </MainMenu>
)

export default Menu
